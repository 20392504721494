.ajax-loading-error {
	margin-top: 70px;
	display: block;
	text-align: center;
	font-size: 23px;
	font-weight: bold;
}
.ajax-notifications .ajax-loading-error {
	text-align:center;
	margin-top:20px;
	font-size: 15px;
}
.ajax-notifications .ajax-loading-animation {
	font-size:15px;
	text-align:center;
	margin-top:70px;
}
