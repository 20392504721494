 /*Messagebox */

.divMessageBox{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;

    background: rgba(0,0,0,0.6);
    z-index:100000;
}
/*
.MessageIE{
    background-image: url("@{base-url}/bg.png");
    
}*/

.MessageBoxContainer{
    position: relative;
    top: 35%;
    color: white;
    position: relative;
    width: 100%;
    background-color:#232323;
    background-color: rgba(0, 0, 0, 0.8);
    padding:20px;
}

.MessageBoxMiddle{
    position: relative;
    left: 20%;
    width: 60%;
}

.MessageBoxMiddle .MsgTitle{
	letter-spacing: -1px;
	font-size: 24px;
	font-weight:300;
}

.MessageBoxMiddle .pText{
    font-style: 30px;
}

.MessageBoxContainer input{
    width: 50%;
    padding: 5px;
}

.MessageBoxContainer select{
    width: 50%;
    padding: 5px;   
}


.MessageBoxButtonSection{
    width: 100%;
    height: 30px;
}

.MessageBoxButtonSection button{
	float: right;
	margin-right: 7px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 14px;
	font-weight: bold;
}

/* End of MessageBox */



.LoadingBoxContainer{
    position: relative;
    top: 20%;
    color: white;
    position: relative;
    width: 100%;
    background-color:#232323;
}

.LoadingBoxMiddle{
    position: relative;
    left: 20%;
    width: 50%;
    padding: 10px;
}

.LoadingBoxContainer .MsgTitle{
    font-size: 26px;
}

.LoadingBoxContainer .pText{
    font-style: 30px;
}

#LoadingPoints{
    position: absolute;

}

/* bigBoxes */
.bigBox{
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #004d60;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    width: 390px;
    height: 150px;
    color: white;
    z-index: 99999;
    box-sizing:content-box;
    -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    border-left: 5px solid rgba(0, 0, 0, 0.15);
    overflow:hidden;
}

.bigBox span{
	font-size: 17px;
	font-weight: 300;
	letter-spacing: -1px;
	padding: 5px 0 !important;
	display: block;
}
.bigBox p{
    font-size: 13px;
    margin-top: 10px;
}

#divMiniIcons{
	position: fixed;
	width: 415px;
	right: 10px;
	bottom: 180px;
	z-index: 9999;
	float:right;

}

.botClose{
	position: absolute;
	right: 10px;
	height: 16px;
	width: 15px;
	cursor: pointer;
	font-size: 18px;
	opacity: .5;
	display: block;
	top: 15px;
}

.botClose:hover {
	opacity: 1;
}

#divMiniIcons .cajita{
	text-align: center;
	vertical-align: middle;
	padding: 4px 6px;
	color: #FFF;
	float: right;
	cursor: pointer;
	display: block;
	background-color: #F00;
	font-size: 17px;
	margin-left: 4px;
	margin-top: 5px;
}

#divMiniIcons .cajita:active {
	top:1px;
	left:1px;
	position:relative;
}

.cajita img{
    width: 23px;
    height: 23px;
    padding-left: 3px;
    padding-top: 3px;
}

.bigBox .bigboxicon{
	font-size: 30px;
	text-align: left;
	position: absolute;
	top: 120px;
	left: 6px;
	z-index: 0;
}

.bigBox .bigboxnumber{
	width: 100%;
	text-align: right;
	font-size: 25px;
}

/* End of  bigBoxes */

/* SmallBox */
#divSmallBoxes{

    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;

}

.SmallBox{
    position: absolute;
    right: 5px;
    top: 20px;
    width: 420px;
    color: white;
    z-index: 9999;
    overflow:hidden;
    border:1px solid transparent;
}

.SmallBox:hover{
    border:1px solid #fff;
    cursor: pointer;
}


.SmallBox .foto{
	font-size: 50px;
	position: absolute;
	left: 17px;
}

.SmallBox .textoFull{
    width: 93%;
    float: left;
    padding-left: 20px;
}

.SmallBox .textoFoto{
	width: 78%;
	margin: 3px 20px 3px 80px;
	float: left;
}

.miniPic {
	position: absolute;
	bottom: 8px;
	right: 9px;
}

.miniIcono{
    height: 100%;
    font-size:20px;
}


.SmallBox span{
	font-size: 17px;
	font-weight: 300;
	letter-spacing: -1px;
	display:block;
	margin:4px 0;
}
.SmallBox p{
    font-size: 13px;
    margin-top: 2px;
}


/* End of SmallBox */

#pageslide {
    /* These styles MUST be included. Do not change. */
    display: none;
    position: absolute;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 999999;
    
    /* Specify the width of your pageslide here */
    width: 305px;
    padding: 20px;
    
    /* These styles are optional, and describe how the pageslide will look */
    background-color: #004d60;
    color: #FFF;
    -webkit-box-shadow: inset 0 0 5px 5px #222;
    -moz-shadow: inset 0 0 0px 0px #222;
    box-shadow: inset 0 0 0px 0px #222;
}

.purehtml{
    color: white;
    font-size: 16px;
}


/* Mobile Phones */
@media screen and (max-width: 450px) and (max-width: 767px) {

/* SmallBox Responsive */
#divSmallBoxes{

    position: fixed;
    width: 90%;
    right: 0;
    top: 0;

}

.SmallBox{
    width: 95%;
}

.SmallBox:hover{
    box-shadow: 0px 0px 10px #888888;
    cursor: pointer;
}

.SmallBox span{
    font-size: 16px;
    /*font-weight:bold;*/
}
.SmallBox p{
    font-size: 12px;
    margin-top: 2px;
}


.SmallBox .textoFull{
    width: 93%;
    float: left;
    padding-left: 20px;
}

.SmallBox .textoFoto{
    width: 55%;
	margin: 3px 20px 3px 80px;
    float: left;    
}


/* End of SmallBox Responsive*/


/* bigBoxes Responsive */
.bigBox{
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #004d60;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    width: 88%;
    height: 150px;
    color: white;
    z-index: 99999;
}


/* End of  bigBoxes  Responsive */


 /*Messagebox Responsive */

.divMessageBox{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background: rgba(0,0,0,0.6);
    z-index:100000;
}

.MessageBoxContainer{
    position: relative;
    top: 25%;
    color: white;
    position: relative;
    width: 100%;
    background-color:#232323;
}

.MessageBoxMiddle{
    position: relative;
    left: 0;
    width: 100%;
    padding: 3px;
}

.MessageBoxMiddle .MsgTitle{
    font-size: 22px;
}

.MessageBoxMiddle .pText{
    font-style: 10px;
}

.MessageBoxContainer input{
    width: 50%;
    padding: 5px;
}

.MessageBoxContainer select{
    width: 50%;
    padding: 5px;   
}


.MessageBoxButtonSection{
    width: 100%;
    height: 30px;
}

.MessageBoxButtonSection button{
	float: right;
	margin-right: 5px;
	padding-left: 15px;
	padding-right: 15px;
}


/* End of MessageBox Responsive */


}




/* Animations */

.animated {
    -webkit-animation-duration: 1s;
       -moz-animation-duration: 1s;
         -o-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: both;
       -moz-animation-fill-mode: both;
         -o-animation-fill-mode: both;
            animation-fill-mode: both;
}

.animated.fast {
    -webkit-animation-duration: 0.4s;
        -moz-animation-duration: 0.4s;
        -ms-animation-duration: 0.4s;
        -o-animation-duration: 0.4s;
        animation-duration: 0.4s;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

@-moz-keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

@-o-keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

@keyframes fadeIn {
    0% {opacity: 0;}    
    100% {opacity: 1;}
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    -moz-animation-name: fadeIn;
    -o-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@-moz-keyframes fadeInRight {
    0% {
        opacity: 0;
        -moz-transform: translateX(20px);
    }
    
    100% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
}

@-o-keyframes fadeInRight {
    0% {
        opacity: 0;
        -o-transform: translateX(20px);
    }
    
    100% {
        opacity: 1;
        -o-transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@-moz-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@-o-keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    -moz-animation-name: fadeOut;
    -o-animation-name: fadeOut;
    animation-name: fadeOut;
}
@-webkit-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
    }
}

@-moz-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -moz-transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        -moz-transform: translateX(-20px);
    }
}

@-o-keyframes fadeOutLeft {
    0% {
        opacity: 1;
        -o-transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        -o-transform: translateX(-20px);
    }
}

@keyframes fadeOutLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    
    100% {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.fadeOutLeft {
    -webkit-animation-name: fadeOutLeft;
    -moz-animation-name: fadeOutLeft;
    -o-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
}
