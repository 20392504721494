// need to override just one or two small stylistic things and don't want to edit the main .less files?  Add those overrides here

/**
 * Override the @red variable for webkit taps
 */

// @red: @lighten;  less-and-js\COMMON_ASSETS\LESS_FILES\variables.less
// come back check for reponsive any red appearing on tap action

#wrap {
    .default(@position:relative, @display:block) {
        position:@position;
        display:@display;
    }
    .void() {
        margin:0;
        padding:0;
        border:0;
    }
    .vrelative(@display:block) {
        .default(relative, @display);
        .void();
    }
    .vabsolute(@display:block) {
        .default(absolute, @display);
        .void();
    }
}
.plain {
    ul& {
        list-style:none;
        #wrap > .void();
    }
    ol& {
        &:extend(ul.plain); // Extending nested Selectors - http://lesscss.org/features/#extend-feature-extending-nested-selectors
    }
}

ul.plain-menu, ul.plain-inline
{
    &:extend(ul.plain);
    li
    {
        &:extend(.reset);
        display:inline-block;
        width:auto;
        height:auto;
        padding:3px 10px;

        &:before
        {
            &:extend(.reset);
            position:absolute;
            display:inline-block;
            content:"|";
            top:2px;
            right:-5px;
        }

        *
        {
            font-size:inherit;
        }
    }
    li:last-child
    {
        &:before
        {
            display:none;
        }
    }
}

ul.plain-inline
{
    li
    {
        &:before
        {
            content:"";
        }
    }
}

@smart-navbar-height: 80px;
.smart-style-0 h4.panel-title{ font-weight: 700; color: #3276B1; background: #EDEDED; }
@asideWidth: 250px;
@asideExpanIconSize: 14px;
@asideExpanIconMT: 1px;
@ribbon-height: auto; //80px
@smart-navbar-color-start: rgba(255, 255, 255, 0);;
@smart-navbar-color-end: rgba(204, 204, 204, 0.9);
@html-background-image: 'fabric_plaid.png';
@html-top-background-image: 'top-bg.jpg';
.dataTables_wrapper { border:solid 1px #ccc; background-color:#ffffff; }
.dataTable {
    border-left:solid 0px transparent;
    border-right:solid 0px transparent;

    td.sorting_1
    {
        white-space: nowrap;
    }

}

.browser-upgrade-head {
    margin: 0;
    height: 80px;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    padding: 0 30px;
    border-width: 0;
    background: #f4f4f4;
}

.browser-upgrade {
    padding: 20px 0 10px;
}

.smart-style-0
{
    #left-panel
    {
        ul > li > a > i
        {
            &[class^="icon-"],
            &[class*=" icon-"]
            {
                font-size:16px;
            }
        }

        .login-info
        {
            ~ nav
            {
                margin-top:5px;
            }

            > span{
                padding:2px 12px;
            }

            img
            {
                border-radius:6%;
            }
        }
    }

    body.minified #left-panel .login-info
    {
        height:58px;

        ~ nav
        {
            margin-top:0;
        }

        > span
        {
            padding:0;
            border-bottom:none;
        }

        img
        {
            width: 42px;
            margin-left: 1px;
            margin-top: 2px;
            border-radius:50%;
        }
    }

    #header{
        background: #fbfbfb url(../img/square_bg.png) !important;
        background-position: 0 0;
    }


    #ribbon
    {
        padding:0;
    }

    .overview {
        .col-xs-3 {
            background: #007CC4;
            border: solid 1px #A4C0CC;
            border-right: none;

            i
            {
                color:white;
                font-size: 32px;
                text-align: center;
                line-height: 65px;
                display: block;
            }
        }
        div.text-center{
            line-height: 1.8;
            font-size: 18px;
            font-weight: 700;

            a
            {
                text-align: center !important;
                line-height: 1.8;
                font-size: 14px;
                font-weight: 600;
            }
        }
        hr.slim{ margin-top:0; margin-bottom:0; border-color:#A4C0CC; }
        .well { border-color: #C9D9E0; border-left:0; }
    }


    li.activity span{ font-family:Arial, Helvetica, sans-serif; font-size: 14px; color: #468847; }

    .page-footer .col-xs-12 span
    {
        color: #bbbbbb;
        font-size: 13px;
        font-weight: 600;
    }

     #dashboardTab
    {
        span
        {
            &[class^="icon-"],
            &[class*=" icon-"]
            {
                font-size: 16px;
                margin-right: 7px;
            }
        }
    }

    .icon-payment-summary
    {
        position:relative;
        font-size:19px;
        top:3px;
        margin-right:3px;
    }

}

@media (max-width: 768px) {
    .smart-style-0
    {
        .overview .col-xs-3 i
        {
            margin-left: -3px;
        }
    }
}

.page-title-wrp {
    h3.page-title {
        color: #ADADAD;
        font-weight: 700;
        margin: 12px 0 12px;
        padding-bottom: 10px;
        border-bottom: solid 1px #ADADAD;
    }
}

/*
 * Case button numbers - Indicator for Case Total
 */
i > em.indicator {
    position: relative;
    display: inline-block;
    top: -3px;
    right: -5px;
    font-size: 15px;
    background-color: #ADADAD;
    color:#ffffff;
    padding: 2px 5px;
    line-height: 20px;
    border-radius: 50%;
    height: 25px;
    width: auto;
    min-width: 25px;
    text-align: center;
}

.js-active i > em.indicator {
    background-color: #426979;
    color:#ffffff;
}

// .smart-style-1 .login-info {
//  height:auto;
// }

// .smart-style-1 .login-info a {
//  margin-top:2px;
// }
// .smart-style-1 .login-info a span {
//  max-width: 136px;
// }

// .smart-style-1 .login-info a:hover {
//  color:#fff;
// }



.smart-style-1
{
    #left-panel
    {
        ul > li > a > i
        {
            &[class^="icon-"],
            &[class*=" icon-"]
            {
                font-size:16px;
            }
        }

        .login-info
        {
            height:auto;
            border-bottom: 1px solid #2c2c2c;

            ~ nav
            {
                margin-top:5px;
            }

            span
            {
                border-bottom:solid 1px #1A1817;
            }

            img {
                width: 54px;
                border-radius:50%;
            }
        }
    }

    body.minified #left-panel .login-info
    {
        height:54px;

        ~ nav
        {
            margin-top:0;
        }

        img
        {
            width: 42px;
            margin-left: -4px;
            margin-top: 1px;
            border-radius:50%;
        }
    }

    #header{
        background: url(../img/gplaypattern.png) !important;
        background-position: 0 0;
    }

    #ribbon
    {
        padding:0;
        background:#930000 !important;
    }

    #ribbon *{
        color:white !important;
    }

    .breadcrumb>li+li:before {
        color: #ffffff !important;
    }

    .page-footer span
    {
        color:white;
    }
}

.dataTables_wrapper
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
}

@media (max-width: 979px) {

}

#logout a {
    padding:0 10px;
    width: auto !important;

    span
    {
        font-size:12px;
        display: inline-block;
        position: relative;
        top: -2px;
    }
}

.smart-form
{
    // fieldset:nth-child(odd){
    //     border-left:solid 5px #007CC4;
    // }
    // fieldset:nth-child(even){
    //     border-left:solid 5px #FC3212;
    // }

    fieldset {
        border-left: solid 5px #DEDEDE;
        // border-bottom: solid 5px #EFEEEE;
        border-right: solid 5px #dedede;
    }

    .modal & {
        fieldset {
            border-right: none;
        }
    }

    .fieldset-separator {
        position:relative;
        height: 0;
        border-top: 1px solid #cdcdcd;
        border-bottom: 1px solid #f5f5f5;
        width:99%;
        margin: 20px auto;

        span {
            position: absolute;
            left: 50%;
            margin-left: -21px;
            background: #e6e6e6 url(../img/div.png) no-repeat 50%;
            width: 36px;
            padding: 0 3px;
            height: 9px;
            display: block;
            top: -4px;
        }
    }

    .fieldset-bottom-flush {
        @color: black;
        @opacity: 0.8;
        border-bottom: solid 1px #DEDEDE;
        -webkit-box-shadow: 0px 15px 15px -20px rgba(red(@color), green(@color), blue(@color), @opacity);
        -moz-box-shadow: 0px 15px 15px -20px rgba(red(@color), green(@color), blue(@color), @opacity);
        -ms-box-shadow: 0px 15px 15px -20px rgba(red(@color), green(@color), blue(@color), @opacity);
        -o-box-shadow: 0px 15px 15px -20px rgba(red(@color), green(@color), blue(@color), @opacity);
        box-shadow: 0px 15px 15px -20px rgba(red(@color), green(@color), blue(@color), @opacity);
    }

    table tr th .label {
        margin-bottom:0;
    }
}

#login-form
{
    fieldset
    {
        border-left:0 solid transparent;
        border-right:0 solid transparent;
    }

    label.input
    {
        i.icon-lock
        {
            &:before
            {
                margin-left:5px;
            }
        }
    }
}

#smart-form-register{
    fieldset
    {
        border-left:0 solid transparent;
        border-right:0 solid transparent;
    }
}

.browsers ul
{
    position:relative;
    display: inline-block;
    list-style: none;
    list-style-position:outside;
    text-align:center;
    width:100%;
    height:auto;
    padding:0;
    margin:0;

    li
    {
        position:relative;
        display:inline-block;
        text-align:center;
        width:108px;
        height:120px;
        padding:0;
        margin:0;

        a
        {
            position: relative;
            display: inline-block;
            padding:0;
            margin:0;

            span
            {
                position: relative;
                display: inline-block;
                width: 100px;
                height: 100px;
                padding:0;
                margin:0;
                background : #E0E0E0 url('@{base-url}/browsers-bg.png') no-repeat 0px 0px scroll;
                background-size: auto 200px;
                -ms-behavior: url(/backgroundsize.min.htc);
            }
            // span.chrome
            // {
            //     background-position: 0px 0px;
            // }
            // span.firefox
            // {
            //     background-position: -100px 0px;
            // }
            // span.ie
            // {
            //     background-position: -200px 0px;
            // }
            // span.safari
            // {
            //     background-position: -300px 0px;
            // }
            // span.opera
            // {
            //     background-position: -400px 0px;
            // }

            // &:hover
            // {
                span.chrome
                {
                    background-position: 0px -100px;
                    background-color: transparent;
                }
                span.firefox
                {
                    background-position: -100px -100px;
                    background-color: transparent;
                }
                span.ie
                {
                    background-position: -200px -100px;
                    background-color: transparent;
                }
                span.safari
                {
                    background-position: -300px -100px;
                    background-color: transparent;
                }
                span.opera
                {
                    background-position: -400px -100px;
                    background-color: transparent;
                }
            // }
        }
    }
}


#chat-body
{
    ul
    {
        li.message
        {
            div.doctor
            {
                &:before
                {
                    position:absolute;
                    display:inline-block;
                    width:10%;
                    height:50%;
                    content:"";
                    border-top:solid 1px #cccccc;
                    top:50%;
                    right:-70px;
                }
                &:after
                {
                    position:absolute;
                    display:inline-block;
                    height:100%;
                    content:"";
                    border-right:solid 1px #cccccc;
                    top:0;
                    right:-70px;
                }
            }
            div.doctor-last
            {
                &:last-child
                {
                    &:before
                    {
                        border-top:none;
                    }
                    &:after
                    {
                        border-right:none;
                    }
                }
            }

            div.vendor
            {
                &:before
                {
                    position:absolute;
                    display:inline-block;
                    width:10%;
                    height:50%;
                    content:"";
                    border-top:solid 1px #cccccc;
                    top:50%;
                    left:-70px;
                }
                &:after
                {
                    position:absolute;
                    display:inline-block;
                    height:100%;
                    content:"";
                    border-right:solid 1px #cccccc;
                    top:0;
                    left:-70px;
                }
            }
        }
    }
}

/**
 * START: Layout with fixed position
 */

// html
// {
//     overflow-y: scroll;
// }
// #left-panel{
//     position:fixed;
// }

// #ribbon{
//     position:fixed;
//     width:100%;
//     z-index:1;
// }

// #content{
//     padding-top:70px;
// }

/**
 * START: Layout with fixed position
 */

@media (max-width: 767px){
    #hide-menu i
    {
        color: inherit;
        font-size: 121%;
    }
}

@media (max-width: 880px){
    .btn-header.transparent a {
        background-color: #f8f8f8;
        border: 1px solid #bfbfbf !important;

        &:hover
        {
            box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08) !important;
        }
    }
}

.i-agree-terms
{
    width:240px;
    margin: 0px auto;

    label.checkbox
    {
        line-height: 1.5;

        i
        {
            top:5px;
        }
    }
}

.smart-form .input-file .virtual-feedback-attach
{
    height: 31px;
    line-height: 31px;
    top: 0;
    right: 0;
}

.virtual-feedback-field
{
    margin-left: 30px;
    margin-right:65px;
}

.virtual-feedback-pin
{
    position: absolute;
    bottom: 30px;
    left: 18px;
}

.virtual-feedback-reply
{
    position: absolute !important;
    font-size: 13px;
    bottom: 21px;
    right: 18px;
    top:inherit !important;
    left:inherit !important;
}

/**
 * Buttons Before Top Menu
 */

.smart-style-0, .smart-style-1 {
    #hide-menu > :first-child > a, .btn-header a {
        margin-top: 30px;
    }
}

.smart-style-0 {
    #hide-menu > :first-child > a, .btn-header a {
        &:hover {
            color: #222;

            span, i {
                color: #222;
            }
        }
    }
}

@media (max-width: 979px) {

    /* responsive menu button */
    #hide-menu > :first-child > a, .btn-header a {
        margin-top:28px !important;
        margin-left:10px;
        width:40px !important;
        height: 40px !important;
        line-height: 30px !important;

        span {
            display:none;
        }
    }
}

/**
 * Top menu
 */

nav.top-menu-rtl {
    float:right;
    padding-right:15px;
}

@media ( max-width: 979px) {
    nav.top-menu-rtl {
        float:none;
        padding-right:0;
    }
}

.top-menu-sub-rtl {
    right:0;
}

.smart-style-0 a {
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s !important;
}

/* next level childs */
.smart-style-1 {
    .menu-on-top nav > ul > li > a:after {
        right: 18px;
    }

    .menu-on-top nav > ul ul > li a:after, .menu-on-top nav ul ul li:hover > a:after, .menu-on-top nav > ul ul > li a:hover:after {
        top:8px;
        right:16px;
    }

    .menu-on-top nav ul ul .active > a:hover, .menu-on-top nav ul ul li.active:hover > a {
        color: #fff !important;
    }


    .menu-on-top nav ul ul li:hover > a {
        color: #fff !important;
        background-color: #4C4F53;
    }

    nav ul ul li > a {
        font-size: 13px;
        padding-left: 0;
        color:#333 !important;
        display: block;
        font-weight: bold !important;

        &:hover {
            color: #fff !important;
        }
    }
}

/**
 * Tooltip
 *
 */

// tooltip variable override is added in LESS_FILES/variable.less
.tooltip {
    .tooltip-inner { font-family: "Open Sans", Arial, sans-serif; font-weight:400; }
}

/**
 * Back to top
 *
 */

#js-back-to-top{
    position:fixed;
    right:-45px;
    bottom:3px;
    background:rgba(255,255,255,1); //rgba(0,0,0,0.8)
    border: solid 2px #FFFFFF; //solid 2px #505050
    width:45px;
    height:45px;
    text-align:center;
    color:#000; //#fff
    line-height:43px;
    overflow:hidden;
    font-size:17px;
    display:block;
    z-index:1111;
    -webkit-transition: color 100ms linear 0ms, background-color 100ms linear 0ms, right 300ms ease 200ms, border 100ms linear 50ms !important;
    -moz-transition: color 100ms linear 0ms, background-color 100ms linear 0ms, right 300ms ease 200ms, border 100ms linear 50ms !important;
    -ms-transition: color 100ms linear 0ms, background-color 100ms linear 0ms, right 300ms ease 200ms, border 100ms linear 50ms !important;
    -o-transition: color 100ms linear 0ms, background-color 100ms linear 0ms, right 300ms ease 200ms, border 100ms linear 50ms !important;
    transition: color 100ms linear 0ms, background-color 100ms linear 0ms, right 300ms ease 200ms, border 100ms linear 50ms !important;

    &:hover {
        background-color: #007CC4;
        border: solid 2px #007CC4;
        color: #FFF;
        cursor: pointer;
    }
}

/**
 * Sticky element on pages to save case, New Case and Drafts
 */

#js-new-patient-wizard {
    z-index:999 !important;

    .is-sticky & {
        -webkit-box-shadow: 0 8px 30px -5px #A7A7A7;
        box-shadow: 0 8px 30px -5px #A7A7A7;
    }
}

/**
 * Points and Upgrade Button
 */

.top-action-button-background-color(@color){
    background-color: lighten(@color, 1.9%);

    // this is messing with IE, leave this inside comment since its not working
    // background-image: -webkit-gradient(linear,left top,left bottom,from(lighten(@color, 1.9%)),to(darken(@color, 1%)));
    // background-image: -webkit-linear-gradient(top,lighten(@color, 1.9%),darken(@color, 1%));
    // background-image: -moz-linear-gradient(top,lighten(@color, 1.9%),darken(@color, 1%));
    // background-image: -ms-linear-gradient(top,lighten(@color, 1.9%),darken(@color, 1%));
    // background-image: -o-linear-gradient(top,lighten(@color, 1.9%),darken(@color, 1%));
    // background-image: linear-gradient(top,lighten(@color, 1.9%),darken(@color, 1%));
}

.top-action-button-boxshadow(@color, @opacity){
    -webkit-box-shadow: inset 0px 0px 4px 1px rgba(red(@color), green(@color), blue(@color), @opacity);
    box-shadow: inset 0px 0px 4px 1px rgba(red(@color), green(@color), blue(@color), @opacity);
}

.top-action-button-active-press(@from, @to, @boxshadow, @opacity) {
    background-color: @from;

    // this is messing with IE, leave this inside comment since its not working
    // background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@from), color-stop(100%,@to));
    // background-image: -webkit-linear-gradient(top, @from 0%,@to 100%);
    // background-image: -moz-linear-gradient(top, @from 0%, @to 100%);
    // background-image: -ms-linear-gradient(top, @from 0%,@to 100%);
    // background-image: -o-linear-gradient(top, @from 0%,@to 100%);
    // background-image: linear-gradient(to bottom, @from 0%,@to 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@from', endColorstr='@to',GradientType=0 );
    .top-action-button-boxshadow(@boxshadow, @opacity);
}

.run-button-settings(@button-selector, @color-name, @color-name-darker, @color-name-darkest, @color-font, @color-font-darker){
    a.@{button-selector} {
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        cursor: pointer !important;
        display: inline-block;
        font-weight: bold;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        .top-action-button-background-color(@color-name);
        border: 1px solid @color-name-darker;
        color: @color-font;
        font-size: 16px;
        font-family: Arial, sans-serif;
        margin-top: 30px;
        margin-left: 6px;

        i { font-size:21px; font-weight:900; }

        span { display: inline-block; padding-top: 2px; }

        &:hover {
            border: 1px solid @color-name-darkest;
            color: @color-font-darker;
            transition: all 0.0s;
            cursor: pointer;
            .top-action-button-boxshadow(black, 0.15);
        }

        &:active {
            color: @color-font-darker;
            .top-action-button-active-press(@from:lighten(@color-name-darker, 10), @to:lighten(@color-name-darker, 15), @boxshadow: #333333, @opacity:0.25);
        }
    }
}

@current-point-color-bg: #f9f9f9;
@current-point-color: #93D08B;
@current-point-color-darker: darken(@current-point-color, 7);
@current-point-color-darkest: darken(@current-point-color, 15);
@current-point-color-font: #3E8235;
@current-point-color-font-darker: darken(@current-point-color-font, 15);

@upgrade-point-color-bg: #f9f9f9;
@upgrade-point-color: #87c7ff;//87ff95
@upgrade-point-color-darker: darken(@upgrade-point-color, 3);
@upgrade-point-color-darkest: darken(@upgrade-point-color, 15);
@upgrade-point-color-font: #315F9A;
@upgrade-point-color-font-darker: darken(@upgrade-point-color-font, 15);;

.run-button-settings(dr-current-points,
                     @current-point-color-bg,
                     @current-point-color-darker,
                     @current-point-color-darkest,
                     @current-point-color-font,
                     @current-point-color-font-darker);
.dr-current-points { margin-right:10px; }

.run-button-settings(dr-case-upgrades,
                     @upgrade-point-color-bg,
                     @upgrade-point-color-darker,
                     @upgrade-point-color-darkest,
                     @upgrade-point-color-font,
                     @upgrade-point-color-font-darker);


// @media (max-width: 880px) {
//     .dr-balance-btn
//     {
//         padding: 10px;
//         margin-top: 19px;
//     }
// }

// @media (max-width: 576px) {
//     .dr-balance-btn
//     {
//         margin-top: -60px;
//         margin-right: 150px;
//         padding: 1px 6px;

//         span
//         {
//             display: block;
//         }
//     }
// }

.smart-form .select select.upgrade-item {
    font-size: 14px;
    color: #0270AF;
    font-weight: 600;
    margin-top:-5px;
}

.smart-form textarea.ta-upgrade-additional-note {
    padding:5px 10px;
    margin-left:-10px;
}

.table tfoot tr:first-child {
    background-color: @gray-lighter;
    background-image: -webkit-gradient(linear,0 0,0 100%,from(@table-header-color-start),to(@table-header-color-end));
    background-image: -webkit-linear-gradient(top,@table-header-color-start 0,@table-header-color-end 100%);
    background-image: -moz-linear-gradient(top,@table-header-color-start 0,@table-header-color-end 100%);
    background-image: -ms-linear-gradient(top,@table-header-color-start 0,@table-header-color-end 100%);
    background-image: -o-linear-gradient(top,@table-header-color-start 0,@table-header-color-end 100%);
    background-image: -linear-gradient(top,@table-header-color-start 0,@table-header-color-end 100%);
}

.table-vertical-middle {
    thead, tbody, tfoot {
        tr {
            th, td {
                vertical-align: middle;
            }
        }
    }
}

.heartbeatAction(){
    @color: hsl(0, 40%, 50%); // red

    0%   { font-weight:100; color: #fff; border: solid 1px darken(@color, 7); background: @color; opacity:0.8; }
    50%  { font-weight:100; color: #fff; border: solid 1px darken(@color, 7); background: saturate(@color, 60); opacity:1; }
    100% { font-weight:100; color: #fff; border: solid 1px darken(@color, 7); background: @color; opacity:0.8; }
}

@-webkit-keyframes lowheartbeat{
    .heartbeatAction();
}
@-moz-keyframes lowheartbeat{
    .heartbeatAction();
}
@-o-keyframes lowheartbeat{
    .heartbeatAction();
}
@keyframes lowheartbeat{
    .heartbeatAction();
}

a.animated.low-heartbeat {
    -webkit-animation-name: lowheartbeat;
    -moz-animation-name: lowheartbeat;
    -o-animation-name: lowheartbeat;
    animation-name: lowheartbeat;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;

    i { font-weight:100; }

    .no-cssgradients & {
        @color: hsl(0, 40%, 50%); // red

        font-weight:100;
        color: #fff;
        border: solid 1px darken(@color, 7);
        background: @color;
        opacity:1;
    }
}

 /**
  * Header Page
  */

.header-pane {
    margin-top: -10px;
}

@media (max-width: 979px) {
    .header-pane {
        margin-top: 5px;
    }
}

/**
 * Special buttons for table
 */

 .button-variant-modified(@color; @background; @border) {
   color: darken(@color, 5);
   background-color: @background;
   border-color: @border;

   &:hover,
   &:focus,
   &:active,
   &.active,
   .open .dropdown-toggle& {
     color: @background;
     background-color: @color;
         border-color: darken(@border, 12%);

        i {
            color: @background;
            font-weight:100;
        }
   }
   &:active,
   &.active,
   .open .dropdown-toggle& {
     background-image: none;
   }
   &.disabled,
   &[disabled],
   fieldset[disabled] & {
     &,
     &:hover,
     &:focus,
     &:active,
     &.active {
       background-color: @background;
           border-color: @border;
     }
   }

   .badge {
     color: @background;
     background-color: @color;
   }

    i {
        color: lighten(@color, 5);
    }
 }

@btn-draftcase-color:#8d887b;
@btn-draftcase-bg:#ffffff;
@btn-draftcase-border:lighten(@btn-draftcase-color, 5);

.btn-draftcase {
    .button-variant-modified(@btn-draftcase-color, @btn-draftcase-bg, @btn-draftcase-border); // modified mixin that is copied from bootstrap mixin.less
}

@btn-payment-color:#51a945;
@btn-payment-bg:#ffffff;
@btn-payment-border:lighten(@btn-payment-color, 5);

.btn-payment {
    .button-variant-modified(@btn-payment-color, @btn-payment-bg, @btn-payment-border); // modified mixin that is copied from bootstrap mixin.less
}

@btn-newcase-color:#007cc4;
@btn-newcase-bg:#ffffff;
@btn-newcase-border:lighten(@btn-newcase-color, 5);

.btn-newcase, .btn-case,  {
    .button-variant-modified(@btn-newcase-color, @btn-newcase-bg, @btn-newcase-border); // modified mixin that is copied from bootstrap mixin.less
}

@btn-approval-color:#3E978A;
@btn-approval-bg:#ffffff;
@btn-approval-border:lighten(@btn-approval-color, 5);

.btn-approval {
    .button-variant-modified(@btn-approval-color, @btn-approval-bg, @btn-approval-border); // modified mixin that is copied from bootstrap mixin.less
}


/**
 * Sign out minor adjustment
 */

.tweak-sign-out {
    position:relative;
    top:-3px;
}

/**
 * Data Table adjustment
 */

table.dataTable tr th, table.dataTable tr td { vertical-align: middle; }
table.dataTable tr th,
table.dataTable tr td {
    vertical-align: middle;

    a, button {
        text-align:center;
    }
}

/**
 * Vertical Icon placement
 */

.btn-icon-vertical {
    display:block;
}

/**
 * [Doctor View] Case Overview:  Buttons Current Active Table
 */

 .run-button-setting-2(@button-selector, @color-name, @color-name-darker, @color-name-darkest, @color-font, @color-font-darker){
     a.@{button-selector} {
         -webkit-border-radius: 2px;
         -moz-border-radius: 2px;
         border-radius: 2px;
         cursor: pointer !important;
         display: inline-block;
         font-weight: 600;
         padding: 6px 12px;
         text-align: center;
         text-decoration: none !important;
         -webkit-user-select: none;
         -moz-user-select: none;
         .top-action-button-background-color(@color-name);
         border: 1px solid @color-name-darker;
         color: @color-font;
         font-size: 14px;
         font-family: 'Open Sans', Arial, Helvetica, sans-serif;

         i { font-size:23px; font-weight:900; }

         &:hover {
             border: 1px solid @color-name-darkest;
             color: @color-font-darker;
             transition: all 0.0s;
             cursor: pointer;
             .top-action-button-boxshadow(@color-name-darkest, 0.15);
         }

         &:active {
             color: @color-font-darker;
             .top-action-button-active-press(@from:lighten(@color-name-darker, 10), @to:lighten(@color-name-darker, 15), @boxshadow: #333333, @opacity:0.25);
         }
     }
 }

.case-tables {
    padding:0;
    margin:0;
    vertical-align: text-bottom;

    .btn-case-table {
        color: #7D7A79;
        font-size:14px;
        border: 1px solid @gray-mid-light;
        background-color: #FBFBFB;

        i {
            font-size:23px;
        }
    }

    .js-active {

            @btn-case-table-color:@brand-info;
            @btn-case-table-bg:#ffffff;

            .run-button-setting-2(btn-case-table,
                                 @btn-case-table-color,
                                 darken(@btn-case-table-color, 5),
                                 darken(@btn-case-table-color, 15),
                                 @btn-case-table-bg,
                                 @btn-case-table-bg);

            // .button-variant-modified(@btn-case-table-color, darken(@btn-case-table-bg, 1), @btn-case-table-border);

            // &:focus, &:active, &:visited {
            //     color: @btn-case-table-color;
            //     background-color: darken(@btn-case-table-bg, 1);

            //     i {
            //         color: @btn-case-table-color;
            //     }
            // }

            // &:hover {
            //     color:inherit;
            //     border-color: darken(@btn-case-table-color, 10);
            //     background-color: darken(@btn-case-table-bg, 1);

            //     i {
            //         color: inherit;
            //     }
            // }
    }
}

/**
 * Minor adjustment to tabs
 */
 .nav-tabs > li > a:hover {
     border-color: #eeeeee #ffffff #b1b1b1;
 }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #FFFFFF;
    background-color: @brand-info;
    border:none;
}

/*
 * Long form header and footer style
 */
.table-title(){
    border: 1px solid #D4D4D4;
    border-left-width: 5px;
    border-left-color: #BDBDBD;

    padding: 10px 20px;
    font-weight: 600;

    background: #F3F3F3;
}
.table-title {
    header&{
        -webkit-box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.35);
        box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.35);
        .table-title();
        margin: 20px 0 1px;
    }

    footer&{
        -webkit-box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.35);
        box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.35);
        .table-title();
    }
}

.smart-form footer .btn {
    margin-top:0;
}

/**
 * Fix btn-default size problem when nested under .smart-form
 * The cause of this fix is /COMMON_ASSETS/LESS_FILES/smartadmin/component-smartforms.less line 8 & 9 causing a hard reset
 */

.smart-form .btn-default {
    padding: 6px 12px;

    &.btn-labeled { padding-top:0; padding-bottom:0;  }
}

/**
 * [VENDOR VIEW] Print Report - Show button
 */

.report-show-btn {
    position:relative;
    display:inline-block;
    width:30px;
    height:20px;
    margin: 0 20px;
    background : transparent url(../img/div.png) repeat-x 0px 8px scroll;
}

/**
 * Bell animation for Package Upgrade
 * http://inspectelement.com/demos/css3/bell/
 */

.animate-bell {
    -webkit-animation: ring 8s 1s ease-in-out infinite;
    -webkit-transform-origin: 50% 0;
    -moz-animation: ring 8s 1s ease-in-out infinite;
    -moz-transform-origin: 50% 0;
    -o-animation: ring 8s 1s ease-in-out infinite;
    -o-transform-origin: 50% 0;
    animation: ring 8s 1s ease-in-out infinite;
    transform-origin: 50% 0;
}

@-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }

    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }

    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
}

@-o-keyframes ring {
    0% { -o-transform: rotate(0); }
    1% { -o-transform: rotate(30deg); }
    3% { -o-transform: rotate(-28deg); }
    5% { -o-transform: rotate(34deg); }
    7% { -o-transform: rotate(-32deg); }
    9% { -o-transform: rotate(30deg); }
    11% { -o-transform: rotate(-28deg); }
    13% { -o-transform: rotate(26deg); }
    15% { -o-transform: rotate(-24deg); }
    17% { -o-transform: rotate(22deg); }
    19% { -o-transform: rotate(-20deg); }
    21% { -o-transform: rotate(18deg); }
    23% { -o-transform: rotate(-16deg); }
    25% { -o-transform: rotate(14deg); }
    27% { -o-transform: rotate(-12deg); }
    29% { -o-transform: rotate(10deg); }
    31% { -o-transform: rotate(-8deg); }
    33% { -o-transform: rotate(6deg); }
    35% { -o-transform: rotate(-4deg); }
    37% { -o-transform: rotate(2deg); }
    39% { -o-transform: rotate(-1deg); }
    41% { -o-transform: rotate(1deg); }

    43% { -o-transform: rotate(0); }
    100% { -o-transform: rotate(0); }
}

@keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
}

/**
 * Minor border adjustment to ribbon when media query kicks in for Top Menu
 */

@media screen and (max-width:979px){
    #ribbon {
       border-bottom: 1px solid #CFCFCF;
       border-top: 1px solid #E6E6E6;
    }
}

/**
 * Guest Form headers
 */
.client-form  {
    .guest-title {
        background-color:transparent;
        border-bottom-style: solid;
        font-size:20px;
        font-weight:700;
        margin-left:0;
        margin-right:0;
        padding-bottom: 10px;
    }
    .guest-title-border-top {
        background-color:transparent;
        border-bottom-style: solid;
        border-top:solid 1px #BDBDBD;
        font-size:20px;
        font-weight:700;
        margin-left:0;
        margin-right:0;
        margin-top:0;
        padding-bottom: 10px;
    }
}

/**
 * Data Tables with better sub selection links (icon reveals when parent is attributed as `active`)
 */
.doctor-data-table-sub-link-option ul {
    li {
        padding:3px;

        a.btn-dr-data-table-sub-link {
            border: 1px solid @gray-mid-light;
            background-color: #FBFBFB;
            color:#7D7A79;

            i {
                color: transparent;
            }

            &:hover {
                border-color: lighten(#7D7A79, 8);
                font-weight: normal !important;

                i {
                    color: inherit;
                }
            }
        }

        &.active {
            @btn-case-table-color:@brand-info;
            @btn-case-table-bg:#ffffff;

            .run-button-setting-2(btn-dr-data-table-sub-link,
                                 @btn-case-table-color,
                                 darken(@btn-case-table-color, 5),
                                 darken(@btn-case-table-color, 15),
                                 @btn-case-table-bg,
                                 @btn-case-table-bg);

            a.btn-dr-data-table-sub-link {
                font-weight: normal !important;
            }
        }
    }
}

/**
 * Vendor view > Case Tab > Virtual Proposal (Standard Upload | Upgrade Upload)
 */
#virtual_setup_proposal_submit_tab > li > a {
    margin-right: 0;
    text-align:center;
}

#virtual_setup_proposal_submit_tab > li:nth-child(2).active > a {
    background-color: #356e35;
    -webkit-box-shadow: 0px -2px 0 #356e35;
       -moz-box-shadow: 0px -2px 0 #356e35;
        -ms-box-shadow: 0px -2px 0 #356e35;
         -o-box-shadow: 0px -2px 0 #356e35;
            box-shadow: 0px -2px 0 #356e35;
}

/**
 * E-Form Wizard, white chevron for the case no.
 */
.fuelux .wizard ul li.case-title-in-wizard {
    font-weight: 900; color: #333333; background-color:#fff;
}
.fuelux .wizard ul li .chevron-white:before {
    border-left: 14px solid #fff;
}

 #virtual_setup_proposal_submit_tab > li:nth-child(2).active > a{
    background-color: #356e35;
    -webkit-box-shadow: 0px -2px 0 #356e35;
       -moz-box-shadow: 0px -2px 0 #356e35;
        -ms-box-shadow: 0px -2px 0 #356e35;
         -o-box-shadow: 0px -2px 0 #356e35;
            box-shadow: 0px -2px 0 #356e35;
}

/**
 * Button should be in consistent width
 */
.btn-min-width-sm {
    min-width: 110px;
}
.btn-min-width-md {
    min-width: 150px;
}
.btn-min-width-lg {
    min-width: 200px;
}

@media (max-width: 768px) {
    .btn-min-width-sm ,
    .btn-min-width-md ,
    .btn-min-width-lg {
        min-width: 100%;
    }
}

/*
 * Responsive tooltips
 */

@media screen and (min-width:74em){ // ~962px
    .popover {
        max-width: 320px !important;
    }
}

@media screen and (max-width:73.9em){ // ~962px
    .popover {
        max-width: @popover-max-width !important;
    }
}

/*
 * Responsive width for tables - completed case and on doctor view - New Case
 */
.table-subj-rw-tablet-sm {
    width:20% !important;
}

@media screen and (max-width:65.38em){ // ~850px
    .table-subj-rw-tablet-sm {
        width:25% !important;
    }
}

@media screen and (max-width:36.92em){ // ~480px
    .table-subj-rw-tablet-sm {
        width:30% !important;
    }
}
